import { createGlobalStyle } from 'styled-components/macro';

import Arrow from 'assets/icons/Arrow.svg';
import ArrowDisabled from 'assets/icons/ArrowDisabled.svg';

export const palette = {
  blue: '#0047FF', // 수정
  red: '#FF0000', // 수정
  yellow: '#FFFF00', // 추가

  hover: {
    blue: '#3579B1'
  },
  primary: {
    blue: '#4295DB',
    white: '#f1f1f1'
  },
  background: {
    navy: '#2A304D',
    black: '#121212'
  },
  semantic: {
    orange: '#D95E2D',
    red: '#FB3836',
    lightRed: '#FF6767',
    green: '#48B16E',
    blue: '#61B7FF',
    yellow: '#DCA048',
    gray: {
      0: 'rgba(241, 241, 241, 0.15)',
      1: 'rgba(241, 241, 241, 0.4)',
      2: '#373736',
      3: 'rgba(18, 18, 18, 0.7)',
      4: 'rgba(18, 18, 18, 0.4)',
      5: 'rgba(48, 48, 47, 0.8)',
      6: 'rgba(241, 241, 241, 0.7)',
      7: 'rgba(48, 48, 47, 0.6)',
      8: '#282828'
    }
  },
  navy: {
    0: '#3D425B',
    1: '#23273D',
    2: '#15192D',
    3: '#585C72',
    4: 'rgba(42, 48, 77, 0.15)',
    5: 'rgba(21, 25, 45, 0.9)',
    6: 'rgba(88, 92, 114, 0.9)'
  },
  grey: {
    0: '#DDDDDD',
    1: '#C4C4C4',
    2: '#C3C6D1',
    3: '#B6BEBD',
    4: '#979797',
    5: '#646464'
  },
  drawingPad: {
    red: '#FF144D',
    yellow: '#FFEE54',
    green: '#50E2A5',
    blue: '#18B5E3',
    white: '#FFFFFF',
    lightGreen: '#1FDE00',
    lightRed: '#EF00D7'
  },
  white: {
    withe_40: '#F1F1F1'
  }
};

export const size = {
  header: 50,
  footer: 100,
  minHeight: 768,
  minWidth: 1100,
  modalWidth: 705,
  portrait: 1080,
};

export const zIndex = {
  floating: 2,
  menu: 3,
  modalPopup: 10,
  toastPopup: 15,
  cookieBanner: 100
};

export const landmarkColor = [
  [`${palette.yellow}`, `${palette.red}`],
  [`${palette.blue}`, `${palette.yellow}`],
  [`${palette.red}`, `${palette.yellow}`],
  [`${palette.background.black}`, `${palette.primary.white}`],
  [`${palette.primary.white}`, `${palette.grey['4']}`],
  [`${palette.grey['1']}`, `${palette.grey['4']}`]
];

export const drawingPenColor = [
  palette.drawingPad.red,
  palette.drawingPad.yellow,
  palette.drawingPad.green,
  palette.drawingPad.blue,
  palette.drawingPad.white
];
export const drawingMeasureColor = [
  palette.drawingPad.red,
  palette.drawingPad.yellow,
  palette.drawingPad.green,
  palette.drawingPad.blue,
  palette.drawingPad.white
];
export const drawingAngleColor = [
  palette.drawingPad.red,
  palette.drawingPad.yellow,
  palette.drawingPad.green,
  palette.drawingPad.blue,
  palette.drawingPad.white
];

export const fontKR = 'Noto Sans KR';
export const fontEN = 'Montserrat';

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${fontKR};
    color: ${palette.primary.white};
    background-color: ${palette.background.navy};
    -webkit-font-smoothing: antialiased;
    font-size: 12px;

    &.viewerPage {
      background-color: ${palette.background.black};
    }
  }

  body.dragging {
    -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }

  body::-webkit-scrollbar {
    display: none;
  }

  button {
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    font-family: inherit;
    color: inherit;
    pointer-events: fill;

    * {
      pointer-events: none;
    }

    &:disabled {
      cursor: default;
    }
  }

  input[type='email'],
  input[type='text'],
  input[type='tel'],
  input[type='password'] {
    outline: 0;
    font-family: ${fontKR};
    padding: 0;
    border-radius: 0;
    border: 0;
  }

  // remove autofill background-color
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${palette.primary.white};
  }
  
  textarea {
    resize: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    font-family: ${fontKR};
    color: inherit;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  
  *:focus {
    outline: 0;
  }
  
  a {
    pointer-events: fill;
    color: inherit;
    text-decoration: none;

    svg {
      pointer-events: none;
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  dl, dt, dd {
    padding: 0;
    margin: 0;
  }

  img, svg {
    vertical-align: middle;
  }
  svg {
    flex-shrink: 0;
  }

  body {
    [lang='ko'] {
      font-family: ${fontKR} !important;
    }
    [lang='en'] {
      font-family: ${fontEN} !important;
    }
  }

  // react-data-picker
   .react-date-picker__wrapper {
    border: 0 !important;
    display: flex;
  }
 .react-date-picker__inputGroup__input {
    min-width: 25px !important;
    color: ${palette.background.black};
    font-family: ${fontEN};
    font-size: 14px;
    text-align: center;

    &.react-date-picker__inputGroup__year {
      min-width: 40px !important;
    }
  }
  .react-date-picker__inputGroup__divider {
    margin: 0 2px;
  }
  .react-date-picker__button {
    margin-left: 4px;
    &:hover {
      svg path {
        stroke: ${palette.primary.blue};
      }
    }
  }

  .react-date-picker__calendar{
    width: 195px !important;
    /* width: 175px !important; */
    z-index: 100;
    /* inset: auto auto 100% 18% !important; */
  }

  .react-calendar {
    padding: 16px 12px 12px 13px;
    border-radius: 15px;
    box-shadow: 0 10px 30px 0 rgba(19, 23, 31, 0.2);
    background-color: #23273D !important; 
    border: 0;
    button:disabled {
      background-color: #646464;
    }
  }
 .react-calendar__navigation {
    // 추가 !important
    height: 20px !important;
    margin: 0 0 10px !important;
    button:enabled:hover,
    button:enabled:focus {
      border-radius: 3px;
      background-color: rgba(230, 230, 230, .3) !important;
    }
  }
  .react-calendar__navigation .react-calendar__navigation__label {
    height: 20px;
    color: ${palette.grey['0']};
    font-size: 10px;
    font-family: ${fontEN};
    background-color: none;
  }
  .react-calendar__navigation__arrow {
    position: relative;
    min-width: 20px !important;
    width: 20px;
    height: 20px;
    text-indent: -100px;
    overflow: hidden;
   
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      background: url(${Arrow}) no-repeat 50% 50% !important;
    }
    &.react-calendar__navigation__next-button {
      &:before {
        transform: rotate(-180deg);
      }
      &:disabled:before {
        background: url(${ArrowDisabled}) no-repeat 50% 50% !important;
        transform: rotate(-180deg);
      }
    }
  }
  .react-date-picker__inputGroup__input:invalid {
    background: none !important;
  }
  .react-calendar button {
    padding: 0;
  }
  .react-calendar abbr {
    text-decoration: none;
  }
  .react-calendar__tile {
    width: 18px !important;
    height: 18px !important;
    padding: 0;
    font-size: 10px;
    color: ${palette.grey['0']};
    font-family: ${fontEN};
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    max-width: 30% !important;
    height: auto !important;
    padding: 1em 1px !important;
    box-sizing: content-box;
  }
  .react-calendar__month-view__days {
    // 추가
    margin:10px 0;

  }
  .react-calendar__month-view__weekdays__weekday {
    height: 18px;
    // 추가 !important
    padding: 0 !important;
  }
  .react-calendar__month-view__weekdays {
    justify-content: space-between;
    padding: 0;
    color: ${palette.grey['4']} !important;
    font-size: 10px;
    font-weight: 500;
    font-family: ${fontEN};
    text-transform: capitalize;
  }
  .react-calendar__month-view__days__day{
    color: ${palette.grey['0']} !important;
    font-weight: 500;
    font-size: 10px;
  }
  .react-calendar__month-view__days__day:hover:not(:disabled),
  .react-calendar__year-view__months__month:hover:not(:disabled),
  .react-calendar__decade-view__years__year:hover:not(:disabled),
  .react-calendar__century-view__decades__decade:hover:not(:disabled) {
    background-color: ${palette.primary.blue} !important;
    border-radius: 3px;
  }
  .react-calendar__month-view__days__day--weekend{
    color: ${palette.grey['0']} !important;
    font-weight: 500;
    font-size: 10px;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    padding: 0;
    color: ${palette.grey['4']} !important;
    font-size: 10px;
    font-family: ${fontEN};
    font-weight: 500;
  }
  .react-calendar__tile--now:enabled:hover {
    font-weight: 700;
    background: ${palette.primary.blue} !important;
  }
  .react-calendar__tile--now:enabled:focus,
  .react-calendar__tile--now {
    font-weight: 600;
    background-color: #23273D !important;
    border-radius: 3px;
  }
  .react-calendar__tile--hasActive,
  .react-calendar__tile--active {
    border-radius: 4px;
    background-color: ${palette.primary.blue} !important;
    color: #fff;
    box-sizing: border-box !important;
  }
  .react-calendar__navigation__label{
    background-color: #23273D !important;
  }
  .react-calendar__navigation__label:hover {
    background-color: ${palette.primary.blue} !important;
  }
  .react-date-picker__button {
    margin-top: -7px;
  }
  .react-date-picker--disabled {
    background: none !important;
    .react-date-picker__button svg path {
      stroke: #646464;
    }
  }
  .react-date-picker__inputGroup__leadingZero {
    margin-left: 5px;
    margin-right: -8px;
    font-family: ${fontEN};
    font-size: 14px;
  }

  // react-custom-scroll bar style
  .rcs-custom-scroll .rcs-inner-container::-webkit-scrollbar {
    display: none;
  }

  .rcs-custom-scroll .rcs-inner-container{
  overflow-x:hidden;
  overflow-y:scroll;
  -webkit-overflow-scrolling:touch;
}
  .rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar{
    opacity:1;
    transition-duration:.2s;
  }
  .rcs-custom-scroll .rcs-inner-container:after {
    content: '';
    position:absolute;
    top:0;
    right:0;
    left:0;
    height:0;
    background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
    pointer-events:none;
    transition:height .1s ease-in;
    will-change: height;
  }

  .rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after{
    height:5px;
    transition:height .15s ease-out;
  }
  .rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container{
    user-select:none
  }

  .rcs-custom-scroll .rcs-custom-scrollbar{
    position:absolute;
    height:100%;
    width:6px;
    right:3px;
    opacity:0;
    z-index:1;
    transition:opacity .4s ease-out;
    padding:6px 0;
    box-sizing:border-box;
    will-change: opacity;
    pointer-events: none;
  }
  .rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl{
    right:auto;
    left:3px
  }
  
  .rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar{
    opacity: 1;
  }
  
  .rcs-custom-scroll .rcs-custom-scroll-handle{
    position:absolute;
    width:100%;
    top:0;
  }


  .rcs-custom-scroll .rcs-inner-handle {
    height: 100% !important;
    margin-top: 0 !important;
    background-color: ${palette.grey['4']} !important;
    border-radius: 2px !important;
  }
  .display, .hide { // GNB scroll
    .rcs-custom-scroll .rcs-custom-scrollbar {
      right: 0;
    }
    .rcs-custom-scroll .rcs-inner-container {
      padding: 0px 15px 0;
    }
  }
  .rcs-custom-scroll {
    width: 100%;

  }
  .rcs-custom-scroll .rcs-outer-container{
    overflow : visible;
  }
`;
