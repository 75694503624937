export const termsLink = {
  view: {
    termsOfCondition: {
      vn: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-vn.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/terms-of-service-en.pdf',
    },
    privacyPolicy: {
      vn: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-vn.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-vn.pdf',
    },
  },
  download: {
    termsOfCondition: {
      vn: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-vn.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/terms-of-service-en.pdf',
    },
    privacyPolicy: {
      vn: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-vn.pdf',
      en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/Legal-Document/privacy-policy-vn.pdf',
    },
  }
};

export const manualLink = {
  vn: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/user-manual-vn/CEP_manual_for_vietnam.pdf',
  en: 'https://ddhaim-manual.s3.ap-northeast-2.amazonaws.com/ceppro/user-manual-vn/CEP_manual_for_vietnam_en.pdf'
};
