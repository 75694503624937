import { palette, zIndex } from 'constants/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DefaultButton from 'components/button/DefaultButton';
import Download from 'assets/icons/Download';
import { manualLink } from 'constants/links';
import { useMutation } from '@apollo/client';
import { UPDATE_DDHAIM_USER } from 'modules/queries/user';
import { GlobalContext } from 'modules/context/GlobalContext';
import YouTube from 'react-youtube';
import Localization from 'i18n';

const Wrapper = styled.div`
  pointer-events: none;
  z-index: ${zIndex.modalPopup};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  `;

const TutorialBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    pointer-events: fill;
    width: 900px;
    background-color: ${palette.navy[6]};
    border-radius: 10px;
    padding-bottom: 15px;
    position: relative;
`;

const Title = styled.div`
    font-size: 25px;
    padding: 10px;
`;

const Content = styled.div`
    font-size: 18px;
    padding: 10px;
    text-align: center;
`;

const CloseButton = styled.button`
  margin-right: 10px; 
  text-decoration: underline;
`;

const NotOpenAnymoreButton = styled.button`
  margin-right: 10px; 
  display: flex;
  align-items: center;
`;

const BottomButtons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom : -25px;
`;

const Tutorial = ({ isTutorial, setIsTutorial }) => {
  const tutorialRef = useRef();
  const [tutorialOff] = useMutation(UPDATE_DDHAIM_USER);
  const globalState = useContext(GlobalContext);
  const playerRef = useRef(null);
  const [notOpenAnymore, setNotOpenAnymore] = useState(false);

  const handleClose = () => {
    setIsTutorial(false);
    if (notOpenAnymore) {
      tutorialOff({
        variables: {
          updateDdhaimUserId: globalState.user.user_info.id,
          data: {
            user_permission: { ...globalState.user.user_info.user_permission, tutorial: false }
          }
        }, onCompleted: (data) => {
          console.log(data);
        } });
    }
  };

  useEffect(() => {
    if (isTutorial) {
      tutorialRef.current.focus();
    }
  }, [isTutorial]);

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return () => {
      // Cleanup function to remove event listeners and destroy the player
      if (playerRef.current) {
        playerRef.current.removeEventListener('onStateChange', onStateChange);
        playerRef.current.destroy();
      }
    };
  }, []);

  const onReady = (event) => {
    event.target.playVideo();
  };

  const onStateChange = (event) => {
    if (event.data === YouTube.PlayerState.PLAYING && !done) {
      setTimeout(stopVideo, 3600000);
      done = true;
    }
  };

  const stopVideo = () => {
    playerRef.current.stopVideo();
  };

  let done = false;

  const manualList = {
    link: globalState.langIndex === 0 ? manualLink.vn : manualLink.en
  };

  return (
    <Wrapper ref={tutorialRef} open={isTutorial}>
      <TutorialBox>
        <Title>
          Welcome to Ceppro v3.0
        </Title>
        <YouTube
          videoId={`${process.env.REACT_APP_YOUTUBE_VIDEO_ID}`}
          src={`${process.env.REACT_APP_YOUTUBE_SRC}`}
          opts={{
            width: '840',
            height: '473',
            playerVars: {
              autoplay: 1, // 자동재생 O
              rel: 0, // 관련 동영상 표시하지 않음 
              modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
            },
          }}
          onReady={onReady}
          onStateChange={onStateChange}
          onPlay={(event) => (playerRef.current = event.target)}
        />
        <Content> 
          {Localization.manual_inform1}
          (<Download />)
          {Localization.manual_inform2}
        </Content>

        <a
          href={manualList.link}
          download 
          rel="noopener noreferrer"
        > 
          <DefaultButton> <Download /> Download </DefaultButton> 
        </a>
        <BottomButtons>
          <NotOpenAnymoreButton onClick={() => setNotOpenAnymore(!notOpenAnymore)}>
            <input type="checkbox" checked={notOpenAnymore} onChange={() => setNotOpenAnymore(!notOpenAnymore)} /> {Localization.doNotOpenAnymore}
          </NotOpenAnymoreButton>
          <CloseButton onClick={handleClose}> {Localization.close} </CloseButton>
        </BottomButtons>
      </TutorialBox>
    </Wrapper>
  );
};

export default Tutorial;