import React, { useState, useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

import { BoardHeader, CloseButton } from 'components/board/BoardComponent';
import ListComponent from 'components/board/ListComponent';
import {
  UploadForms,
  UploadFormTitle,
} from 'components/board/CreateComponent';
import FormItem, { FormRow } from 'components/forms/FormItem';
import BackButton from 'components/button/BackButton';
import DefaultButton from 'components/button/DefaultButton';
import ViewBoard, {
  UploadFile,
  ViewHistory,
  ViewHistoryBoard,
  ViewImageItem
} from 'components/board/ViewComponent';
import { pagePaths } from 'constants/paths';
import { ToastContext } from 'modules/context/ToastContext';
import { GlobalContext } from 'modules/context/GlobalContext';
import { GENDER_TYPE } from 'constants/types';
import {
  GET_ALL_CHART_LIST_OF_PATIENT,
  GET_ALL_PATIENT_LIST_OF_DOCTOR,
  GET_ONE_PATIENT_QUERY,
  UPDATE_PATIENT
} from 'modules/queries/patients';
import IconTimes from 'assets/icons/Times';

import formValidate from 'utils/formValidate';
import { useCookies } from 'react-cookie';
import Localization from 'i18n';
import { viewerAPI } from 'modules/api/viewerAPI';
import { fetchImage, getDateFormat } from 'utils/functions';
import { UPDATE_CHART_MUTATION } from 'modules/queries/chart';
import Loading from 'components/common/Loading';
import { PopupContext } from 'modules/context/PopupContext';

const initData = {
  src: 'add',
  id: null,
  url: null,
  created_at: null
};
const initError = {
  patient_id: '',
  name: '',
  birth: ''
};

const maxLengthValue = 50;

const ViewContainer = ({ history, location, mode }) => {
  const cookies = useCookies();
  const globalContext = useContext(GlobalContext);
  const toastState = useContext(ToastContext);
  const [userForm, setUserForm] = useState({});
  const [errorMessage, setErrorMessage] = useState(initError);
  const [data, setData] = useState([initData]);
  const [chartsInPatient, setChartsInPatient] = useState();
  const popupState = useContext(PopupContext);
  const [modalOpen, setModalOpen] = useState(false);
  const modalRef = useRef();

  const id = mode === 'modal'
    ? globalContext.viewPatientID
    : location.pathname.split('/')[2];

  const onePatient = useQuery(
    GET_ONE_PATIENT_QUERY,
    {
      variables: {
        ddhaimPatientId: id
      },
      onCompleted: (data) => {
        setUserForm({
          patient_id: data.ddhaimPatient.data.id,
          viewerId: data.ddhaimPatient.data.attributes.patient_id,
          name: data.ddhaimPatient.data.attributes.first_name,
          birth: new Date(data.ddhaimPatient.data.attributes.birth_info),
          gender: data.ddhaimPatient.data.attributes.gender === true ? 1 : 0
        });
        globalContext.update({ viewPatientID: data.ddhaimPatient.data.id });
      },
      onError: (error) => {
        console.log(error);
      }
    },
    []
  );

  const [getAllChartListOfPatient] = useLazyQuery(
    GET_ALL_CHART_LIST_OF_PATIENT,
    {
      variables: {
        filters: {
          deletedAt: {
            null: true
          },
          ddhaim_patient: {
            id: {
              eq: userForm.patient_id && userForm.patient_id
            },
            ddhaim_user: {
              id: {
                eq: globalContext.user.user_info.id
              }
            }
          }
        },
        sort: ['createdAt:desc']
      },
      onCompleted: (responsedata) => {
        setChartsInPatient(responsedata.ddhaimCharts.data);
      },
      onError: (error) => {
        console.log(error);
      }
    }
  );

  const handleCloseModal = () => {
    // modal close시 modalMode 기본값으로 수정
    globalContext.update({ displayModal: false, modalMode: 'list', rerender: !globalContext.rerender });
  };

  const handleBack = () => {
    if (mode === 'modal') {
      globalContext.update({ modalMode: 'list' });
    } else {
      history.goBack();
    }
  };

  const [deleteChartMutation] = useMutation(UPDATE_CHART_MUTATION);

  const deleteChart = (id) => {
    try {
      deleteChartMutation({
        variables: {
          updateDdhaimChartId: id,
          data: {
            deletedAt: new Date().toISOString()
          }
        },
        refetchQueries: [
          { query: GET_ALL_PATIENT_LIST_OF_DOCTOR },
        ],
        onCompleted: () => {
          popupState.update({
            display: true,
            title: Localization.delete_chart,
            text: Localization.delete_chart_succeed,
            textConfirm: Localization.confirm
          });
          globalContext.update({ isLoading: false, rerender: !globalContext.rerender });
          if (data.length === 2) {
            history.push(pagePaths.list);
          }
        },
        onError: (error) => {
          console.log(error);
          popupState.update({
            display: true,
            title: Localization.delete_chart,
            text: Localization.failed_chart_delete,
            textConfirm: Localization.confirm
          });
          globalContext.update({ isLoading: false });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // 차트 삭제 기능
  const handleDelChart = (idx) => {
    // loading on
    popupState.update({
      display: true,
      title: Localization.delete_chart,
      text: Localization.ask_delete_chart,
      textConfirm: Localization.confirm,
      textCancel: Localization.cancel,
      fnConfirm: () => {
        globalContext.update({ isLoading: true });
        deleteChart(data[idx].strapiId);
      }
    });
  };

  const handleViewer = (idx) => {
    history.push(pagePaths.viewer.replace(':id', data[idx].id));
  };
  const handleAddImage = (idx) => {
    if (globalContext.modalMode === 'view') {
      globalContext.update({
        displayModal: true,
        modalMode: 'view/change',
        chartId: data[idx].strapiId
      });
    } else {
      globalContext.update({
        displayModal: true,
        modalMode: 'change',
        chartId: data[idx].id
      });
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setUserForm((prev) => ({ ...prev, [e.target.name]: value }));
  };
  const handleValidate = (e) => {
    const returnData = formValidate(e.target.name, userForm);
    setErrorMessage((prev) => ({ ...prev, ...returnData }));
  };
  const handleDatePicker = (value) => {
    setUserForm((prev) => ({ ...prev, birth: value }));
  };
  const handleSelectOne = (e) => {
    const newObj = {};
    newObj[e.target.getAttribute('name')] = Number(
      e.target.getAttribute('value')
    );
    setUserForm({ ...userForm, ...newObj });
  };

  const [updatePatientInfo] = useMutation(UPDATE_PATIENT, {
    variables: {
      updateDdhaimPatientId: userForm.patient_id,
      data: {
        first_name: userForm.name,
        birth_info: getDateFormat(userForm.birth),
        gender: userForm.gender === 0 ? Boolean(false) : Boolean(true)
      }
    },
    onCompleted: () => {
      globalContext.update({ displayModal: false, isLoading: false, rerender: !globalContext.rerender });
      toastState.display(true);
      toastState.theme('viewer');
      toastState.update({
        kind: 'success',
        title: Localization.success,
        text: Localization.success_save
      });
    },
    onError: (error) => {
      console.log('ERROR:', error);
    }
  });

  const ClinicHistroy = data.map((value, index) => {
    const cutNumber = 3;

    if (index % cutNumber === 0) {
      return (
        <ViewHistory key={index}>
          {data.map((v, idx) => {
            if (v.src === 'add' && index <= idx) {
              return (
                <UploadFile onClick={() => handleAddImage(idx)} key={idx} />
              );
            } else if (index + cutNumber > idx && index <= idx) {
              if (v.src === 'history') {
                return (
                  <ViewImageItem
                    chartInfo={v}
                    data={data}
                    alt="image"
                    onClick={() => handleViewer(idx)}
                    changeImg={() => handleAddImage(idx)}
                    deleteChart={() => handleDelChart(idx)}
                    key={idx}
                  />
                );
              } else if (v.src === 'lost') {
                return (
                  <ViewImageItem
                    chartInfo={v}
                    data={data}
                    alt="image"
                    date="LOST"
                    onClick={() => handleViewer(idx)}
                    changeImg={() => handleAddImage(idx)}
                    key={idx}
                  />
                );
              }
            }
          })}
        </ViewHistory>
      );
    }
  });

  useEffect(() => {
    try {
      if (onePatient.error) {
        return <>error</>;
      }
      if (onePatient.loading) {
        return <>loading...</>;
      }
    } catch (error) {
      console.log(error);
    }
  }, [onePatient.data]);

  useEffect(() => {
    try {
      getAllChartListOfPatient();
    } catch (error) {
      console.log(error);
    }
  }, [globalContext.rerender]);

  useEffect(() => {
    try {
      if (chartsInPatient) {
        const getUrlPromises = [];
  
        const chartIdList = [];
        const strapiChartIdList = [];
        let chartIdValue;
        let shootDateInfoValue;
        chartsInPatient.map((chart) => {
          const shootDateInfo = [];
          const findCephaloImage = chart.attributes.ddhaim_chart_data.data;
  
          findCephaloImage.map((el) => {
            if (el.attributes.image_type_cd === '00700') {
              shootDateInfo.push(el.attributes.shoot_date);
            }
          });
  
          const chartId = chart.id;
          chartIdValue = chartId;
          shootDateInfoValue = shootDateInfo[0];
          const thumbnailRq = {
            chart_id: chartId,
            image_type_cd: ['00700'],
            thumbnail_only_check: 'true'
          };

          let blobData;
          let fileImg;
          (async () => {
            try {
              const response = await viewerAPI.fileDownload(thumbnailRq, cookies);
              if (response.length === 0) {
                const originRq = {
                  chart_id: chartIdValue,
                  image_type_cd: ['00700'],
                };
                const downloadResult = await viewerAPI.fileDownload(originRq, cookies)
                  .then(async(res) => {
                    const fetchImg = await fetch(res[0].presignedPath);
                    blobData = await fetchImg.blob();
                    // blobImg = await fetc
                  }).catch((error) => {
                    console.log(error);
                  });

                fileImg = new File([blobData], `${chartIdValue}_00700.jpg`, { type: 'image/jpeg' });

                const formData = new FormData();
                formData.append('file', fileImg, `${chartIdValue}_00700.jpg`);

                viewerAPI.fileUpload(
                  {
                    chart_id: chartIdValue,
                    image_type_cd: '00700',
                    original_name: `${chartIdValue}_00700.jpg`,
                    shoot_date: shootDateInfoValue
                  },
                  fileImg
                ).then((res) => {
                  // console.log(res);
                }).catch((error) => {
                  console.log(error);
                });
              }
              getUrlPromises.push(response);
              chartIdList.push(chart.attributes.uuid);
              strapiChartIdList.push(chart.id);

              const thumbnailData = [{ src: 'add', url: '', id: '', created_at: '' }];
              getUrlPromises.map((v, idx) => {
                if (v !== undefined) {
                // if (v !== undefined && v.length >= 1) {
                  thumbnailData.push({
                    src: 'history',
                    url: v[0].presignedPath,
                    id: chartIdList[idx],
                    strapiId: strapiChartIdList[idx],
                    created_at: v[0].create_at,
                    shoot_date: v[0].shoot_date
                  });
                } else {
                  thumbnailData.push({
                    src: 'lost',
                    url: '',
                    id: chartIdList[idx],
                    strapiId:strapiChartIdList[idx],
                    created_at: '',
                    shoot_date: ''
                  });
                }
              });
              setData(thumbnailData);
            } catch (error) {
              console.log(error);
            }
          })();

        });

      }
    } catch (error) {
      console.log(error);
    }
  }, [chartsInPatient]);

  useEffect(() => {
    try {
      // after close add file modal
      if (!globalContext.displayModal || globalContext.modalMode === 'view') {
        if (userForm.patient_id) {
          setTimeout(() => {
            getAllChartListOfPatient({
              variables: {
                filters: {
                  deletedAt: {
                    null: true
                  },
                  ddhaim_patient: {
                    id: {
                      eq: userForm.patient_id && userForm.patient_id
                    },
                    ddhaim_user: {
                      id: {
                        eq: globalContext.user.user_info.id
                      }
                    }
                  }
                },
                sort: ['createdAt:desc']
              }
            });
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    globalContext.modalMode,
    globalContext.displayModal,
    userForm.patient_id
  ]);

  useEffect(() => {
    return () => {
      globalContext.update({ rerender: !globalContext.rerender });
    };
  }, []);

  useEffect(() => {
    if (modalOpen) {
      modalRef.current.focus();
    }
  }, [modalOpen]);

  useEffect(() => {
    try {
      if (location !== undefined && mode !== undefined && history !== undefined) {
        setModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <>
      {globalContext.isLoading && <Loading />}
      <ListComponent
        mode={mode} onClose={handleCloseModal}
      >
        <BoardHeader
          theme="view"
          title={Localization.update_patient_info}
        >
          {mode !== 'modal' ? <BackButton theme="list" onClick={handleBack} />
            : (
              <CloseButton type="button" onClick={handleCloseModal}>
                <IconTimes />
              </CloseButton>
            )}
        </BoardHeader>

        <ViewBoard
          tabIndex="0"
          ref={modalRef}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              globalContext.update({
                displayModal: false,
                modalMode : 'view'
              });
            }
          }}>
          <UploadFormTitle> {Localization.summary_info} </UploadFormTitle>

          <FormRow>
            <FormItem
              label={Localization.patient_id}
              type="text"
              name="patient_id"
              maxLength={maxLengthValue}
              readonly
              value={userForm.viewerId}
            />
            <FormItem
              label={Localization.name}
              type="text"
              name="name"
              maxLength={maxLengthValue}
              value={userForm.name}
              onChange={handleChange}
              onBlur={handleValidate}
              errorMessage={errorMessage.name}
            />
          </FormRow>

          <FormRow>
            <FormItem
              label={Localization.birthday}
              type="date"
              name="birth"
              maxLength={maxLengthValue}
              value={userForm.birth}
              onDateChange={(date) => {
                handleDatePicker(date);
              }}
              errorMessage={errorMessage.birth}
            />
            <FormItem
              label={Localization.gender}
              name="gender"
              type="selectOne"
              selectOneList={GENDER_TYPE}
              onSelectOne={handleSelectOne}
              selectedOne={Number(userForm.gender)}
            />
          </FormRow>

          <UploadForms style={{ display: 'flex', justifyContent: 'end' }}>
            <DefaultButton
              disabled={(errorMessage.name).length !== 0}
              onClick={() => {
                globalContext.update({ isLoading: true });
                setTimeout(() => {
                  updatePatientInfo();
                }, 1000);
              }}
              size="middle"
            >
              {Localization.save}
            </DefaultButton>
          </UploadForms>
          <FormRow />

          <UploadForms>
            <UploadFormTitle>{Localization.patient_history}</UploadFormTitle>

            <ViewHistoryBoard>{ClinicHistroy}</ViewHistoryBoard>
          </UploadForms>
        </ViewBoard>
      </ListComponent>
    </>
  );
};

export default withRouter(ViewContainer);
