export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;
export const NAME_REGEX = /^[a-zA-Z가-힣0-9\sÁ-ỵ]*$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=])[A-Za-z\d!@#$%^&+=]{8,24}$/;
export const NUMBER_REGEX = /^[0-9]{0,}$/;
export const MOBILE_REGEX = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/; // /(^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/;
export const PHONE_REGEX = /^[0-9]{7,8}$/;
// export const PHONE_REGEX = /^[0-9]{3,4}-[0-9]{4}/; 
// export const PHONE_REGEX = /^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/; // /(^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/;
export const DECIMAL_POINT_NUMBER_REGEX = /^[0-9.]{0,}$/;
// 소수점 둘째 자리까지만 허용하는 정규표현식
export const DECIMAL_RESTRICTION = /^[\d]*\.?[\d]{0,2}$/;
