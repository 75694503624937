import React, { useContext, useEffect, useState } from 'react';
import Footer from 'components/common/Footer';
import { termsLink } from 'constants/links';
import Localization from 'i18n';
import { GlobalContext } from 'modules/context/GlobalContext';

const FooterContainer = () => {
  const globalState = useContext(GlobalContext);
  const [termsOfCondition, setTermsOfCondition] = useState();
  const [privacyPolicy, setPrivacyPolicy] = useState();

  useEffect(() => {
    try {
      if (globalState.langIndex !== undefined) {
        setTermsOfCondition(globalState.langIndex === 0 ? termsLink.view.termsOfCondition.vn : termsLink.view.termsOfCondition.en);
        setPrivacyPolicy(globalState.langIndex === 0 ? termsLink.view.privacyPolicy.vn : termsLink.view.privacyPolicy.en);    
      }
    } catch (error) {
      console.log(error);
    }
  }, [globalState.langIndex]);

  return (
    <Footer
      links={[
        { link: 'https://www.ddhinc.net/', text: 'DDHaim' },
        {
          link: termsOfCondition,
          text: `${Localization.term_of_service}`
        },
        {
          link: privacyPolicy,
          text: `${Localization.privacy_policy}`
        }
      ]}
      info={`${Localization.version_info} | ${Localization.company_info}`}
      item={`${Localization.item}`}
      model={`${Localization.model}`}
      product={`${Localization.product}`}
      ddhaimceppro={`${Localization.ddhaim_ceppro}`}
      cepprocloud={`${Localization.ceppro_cloud}`}
      udicode={`${Localization.udi_code}`}
      dentalsoftware={`${Localization.dental_software}`}
      copyright="©2019. DDH, Inc. ALL RIGHTS RESERVED"
    />
  );
};

export default FooterContainer;
